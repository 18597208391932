export const Icons = {
    aws: '/skill/aws.svg',
    c: '/skill/C.svg',
    cplusplus: '/skill/C++.svg',
    dart: '/skill/dart.svg',
    docker: '/skill/docker.svg',
    flutter: '/skill/flutter.svg',
    git: '/skill/git.svg',
    github: '/skill/github.svg',
    gitlab: '/skill/gitlab.svg',
    javascript: '/skill/javascript.svg',
    jenkins: '/skill/jenkins.svg',
    linux: '/skill/linux.svg',
    mongodb: '/skill/mongodb.svg',
    mysql: '/skill/mysql.svg',
    nestjs: '/skill/nestjs.svg',
    nextjs: '/skill/nextjs.svg',
    postgresql: '/skill/postgresql.svg',
    python: '/skill/python.svg',
    react: '/skill/react.svg',
    reactnative: '/skill/reactNative.svg',
    tailwindcss: '/skill/tailwindcss.svg',
    typescript: '/skill/typescript.svg',
  };
  