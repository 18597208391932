import React from 'react';
import Tag, { TagProps } from './Tag';

export interface ProjectProps {
  logoUrl: string;
  title: string;
  description: string;
  tags: TagProps[];
}

function Project({ logoUrl, title, description, tags }: ProjectProps) {
  return (
    <div className="w-full max-h-max bg-tertiary rounded-[10px] flex text-black items-center">
      <img
        src={logoUrl}
        alt="Logo/Image"
        className="rounded-l-[10px] italic font-light object-contain p-4 w-[25%] sm:p-6 sm:w-[25%] md:p-10 md:w-[25%] lg:p-5 lg:w-[25%] xl:p-6 xl:w-[25%] 2xl:p-10 2xl:w-[25%]"
      />
      <div className="justify-between flex flex-col py-2 pr-4 space-y-2 w-[75%] sm:py-3 sm:pr-6 sm:space-y-2 sm:w-[75%] md:py-5 md:pr-8 md:space-y-2 md:w-[75%] lg:py-3 lg:pr-5 lg:space-y-2 lg:w-[75%] xl:py-5 xl:pr-6 xl:space-y-2 xl:w-[75%] 2xl:py-5 2xl:pr-8 2xl:space-y-2 2xl:w-[75%]">
        <div className="space-y-0 sm:space-y-1 md:space-y-2 lg:space-y-1 xl:space-y-2 2xl:space-y-2">
          <div className="font-bold text-sm sm:text-xl md:text-2xl lg:text-lg xl:text-xl 2xl:text-2xl">
            {title}
          </div>
          <div className="text-justify text-xs sm:text-base md:text-xl lg:text-sm xl:text-base 2xl:text-lg">
            {description}
          </div>
        </div>
        <div className="flex flex-wrap space-x-2 sm:space-x-2 md:space-x-2 lg:space-x-2 xl:space-x-2 2xl:space-x-2">
          {tags.map((tag, index) => (
            <Tag key={index} text={tag.text} hexaColor={tag.hexaColor} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Project;
