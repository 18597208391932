import React, { useEffect, useRef } from 'react';

const GradientBackground: React.FC = () => {
  const gradientRef = useRef<HTMLDivElement>(null);
  const colors = [
    [10, 10, 10],
    [80, 80, 80],
    [10, 10, 10],
    [80, 80, 80],
    [10, 10, 10],
    [80, 80, 80]
  ];

  const gradientSpeed = 0.006;
  let step = 0;
  const colorIndices = [0, 1, 2, 3];

  const interpolateColors = (color1: number[], color2: number[], factor: number) => {
    return color1.map((c, i) => Math.round(c + factor * (color2[i] - c)));
  };

  const updateGradient = () => {
    if (!gradientRef.current) return;

    const c0_0 = colors[colorIndices[0]];
    const c0_1 = colors[colorIndices[1]];
    const c1_0 = colors[colorIndices[2]];
    const c1_1 = colors[colorIndices[3]];

    const color1 = interpolateColors(c0_0, c0_1, step);
    const color2 = interpolateColors(c1_0, c1_1, step);

    gradientRef.current.style.background = `linear-gradient(to right, rgb(${color1.join(',')}), rgb(${color2.join(',')}))`;

    step += gradientSpeed;
    if (step >= 1) {
      step %= 1;
      colorIndices[0] = colorIndices[1];
      colorIndices[2] = colorIndices[3];
      colorIndices[1] =
        (colorIndices[1] + Math.floor(1 + Math.random() * (colors.length - 1))) % colors.length;
      colorIndices[3] =
        (colorIndices[3] + Math.floor(1 + Math.random() * (colors.length - 1))) % colors.length;
    }
  };

  useEffect(() => {
    const interval = setInterval(updateGradient, 20);
    return () => clearInterval(interval);
  }, []);

  return <div ref={gradientRef} className="absolute inset-0" style={{ zIndex: -1 }} />;
};

export default GradientBackground;
