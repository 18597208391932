import Header from '../Components/Header';
import React, { useEffect, useRef, useState } from 'react';
import Skill from '../Components/Projects/Skill';
import { Icons } from '../svgs';
import Project, { ProjectProps } from '../Components/Projects/Project';
import projectsData from './projects.json';
import { TagProps } from '../Components/Projects/Tag';

function ProjectsPage() {
  const [atBottom, setAtBottom] = useState(false);
  const [atTop, setAtTop] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const scrollElement = scrollRef.current;
    if (scrollElement === null) return;
    const isAtBottom =
      scrollElement.scrollHeight - scrollElement.scrollTop <= scrollElement.clientHeight;
    const isAtTop = scrollElement.scrollTop === 0;
    setAtTop(isAtTop);
    setAtBottom(isAtBottom);
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement === null) return;
    scrollElement.addEventListener('scroll', handleScroll);
    return () => {
      scrollElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const smallTitleStyle =
    'font-bold text-base sm:text-lg md:text-2xl lg:text-xl xl:text-xl 2xl:text-2xl';
  const colFillSkillsStyle =
    'w-full grid grid-cols-2 space-y-1 sm:space-y-1 md:space-y-2 lg:space-y-1 xl:space-y-1 2xl:space-y-2';
  const colSkillsStyle =
    'w-[32%] space-y-0 sm:space-y-0 md:space-y-3 lg:space-y-0 xl:space-y-1 2xl:space-y-3';

  return (
    <div className="flex flex-col lg:flex-row w-full">
      <Header pathImage="meProjects.jpg" />
      <div className="text-white h-full pb-10 lg:pb-0 lg:min-h-screen justify-start items-center flex flex-col w-[100%] text-xs sm:text-sm md:text-base lg:justify-center lg:items-start lg:text-sm lg:w-[55%] xl:text-base xl:w-[50%] 2xl:text-lg 2xl:w-[50%]">
        <div className="bg-primary bg-opacity-20 rounded-[10px] text-justify p-4 space-y-4 w-[95%] sm:p-8 sm:space-y-2 sm:w-[90%] md:p-8 md:space-y-2 md:w-[90%] lg:p-6 lg:space-y-2 lg:w-[95%] xl:p-6 xl:space-y-4 xl:w-[95%] 2xl:p-10 2xl:space-y-4 2xl:w-[95%]">
          <h1 className="text-left font-bold drop-shadow-title text-xl pb-0 sm:text-3xl sm:pb-2 md:text-5xl md:pb-4 lg:text-4xl lg:pb-4 xl:text-5xl xl:pb-2 2xl:text-6xl 2xl:pb-4">
            CE QUE J&apos;AI RÉALISÉ
          </h1>
          <div className="w-full flex justify-between sm:space-x-2 md:space-x-6 lg:space-x-2 xl:space-x-4 2xl:space-x-6">
            <div className={colSkillsStyle}>
              <h2 className={smallTitleStyle}>Outils</h2>
              <div className={colFillSkillsStyle}>
                <Skill text="Aws" iconUrl={Icons.aws} />
                <Skill text="Linux" iconUrl={Icons.linux} />
                <Skill text="Git" iconUrl={Icons.git} />
                <Skill text="Gitlab" iconUrl={Icons.gitlab} />
                <Skill text="Github" iconUrl={Icons.github} />
                <Skill text="Jenkins" iconUrl={Icons.jenkins} />
                <Skill text="Docker" iconUrl={Icons.docker} />
              </div>
            </div>
            <div className={colSkillsStyle}>
              <h2 className={smallTitleStyle}>Langages</h2>
              <div className={colFillSkillsStyle}>
                <Skill text="C" iconUrl={Icons.c} />
                <Skill text="Typescript" iconUrl={Icons.typescript} />
                <Skill text="C++" iconUrl={Icons.cplusplus} />
                <Skill text="Dart" iconUrl={Icons.dart} />
                <Skill text="Python" iconUrl={Icons.python} />
                <Skill text="Javascript" iconUrl={Icons.javascript} />
              </div>
            </div>
            <div className={colSkillsStyle}>
              <h2 className={smallTitleStyle}>Technologies</h2>
              <div className={colFillSkillsStyle}>
                <Skill text="Flutter" iconUrl={Icons.flutter} />
                <Skill text="NextJs" iconUrl={Icons.nextjs} />
                <Skill text="React Native" iconUrl={Icons.reactnative} />
                <Skill text="React" iconUrl={Icons.react} />
                <Skill text="NestJs" iconUrl={Icons.nestjs} />
                <Skill text="TailwindCss" iconUrl={Icons.tailwindcss} />
                <Skill text="MongoDb" iconUrl={Icons.mongodb} />
                <Skill text="MySQL" iconUrl={Icons.mysql} />
                <Skill text="PostgreSQL" iconUrl={Icons.postgresql} />
              </div>
            </div>
          </div>
          <div className="relative">
            <div
              className={`absolute inset-x-0 bottom-0 h-20 bg-gradient-to-t from-primary opacity-60 to-transparent transition-opacity duration-300 ${
                atBottom ? 'opacity-0' : 'opacity-100'
              } pointer-events-none`}></div>
            <div
              className={`absolute inset-x-0 top-0 h-20 bg-gradient-to-t from-transparent opacity-60 to-primary transition-opacity duration-300 ${
                atTop ? 'opacity-0' : 'opacity-100'
              } pointer-events-none`}></div>
            <div
              className="overflow-y-scroll space-y-2 h-[950px] sm:h-[950] md:h-[950px] lg:h-[550px] xl:h-[500px] 2xl:h-[450px]"
              ref={scrollRef}>
              {projectsData.map((project: ProjectProps, index: number) => (
                <Project
                  key={index}
                  title={project.title}
                  description={project.description}
                  logoUrl={project.logoUrl}
                  tags={project.tags as TagProps[]}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsPage;
