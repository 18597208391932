import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProjectsPage from './Projects/ProjectsPage';
import ContactPage from './Contact/ContactPage';
import ProfilPage from './Profil/ProfilePage';
import Button from './Components/Button';
import GradientBackground from './Components/GradientBackground';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Router>
      <div className="relative min-h-screen w-screen">
        <GradientBackground />
        <Button
          icon={
            <svg
              width="25"
              height="25"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.19231 19.0769C1.8508 19.0769 2.38462 19.6107 2.38462 20.2692C2.38462 22.5511 2.38714 24.1426 2.5486 25.3435C2.70543 26.5101 2.9923 27.1277 3.43227 27.5677C3.87225 28.0078 4.48999 28.2946 5.6565 28.4515C6.85731 28.6128 8.44884 28.6154 10.7308 28.6154H20.2692C22.5512 28.6154 24.1426 28.6128 25.3435 28.4515C26.5101 28.2946 27.1277 28.0078 27.5677 27.5677C28.0078 27.1277 28.2946 26.5101 28.4515 25.3435C28.6128 24.1426 28.6154 22.5511 28.6154 20.2692C28.6154 19.6107 29.1492 19.0769 29.8077 19.0769C30.4662 19.0769 31 19.6107 31 20.2692V20.3565C31 22.5306 31 24.283 30.8148 25.6613C30.6224 27.0922 30.2108 28.2969 29.254 29.2538C28.297 30.2108 27.0923 30.6224 25.6613 30.8148C24.283 31 22.5306 31 20.3565 31H10.6435C8.46942 31 6.71702 31 5.33876 30.8148C3.90781 30.6224 2.70299 30.2108 1.7461 29.254C0.789197 28.2969 0.377628 27.0922 0.185238 25.6613C-6.28707e-05 24.283 -3.09619e-05 22.5306 8.32951e-07 20.3565C8.32951e-07 20.3274 8.32951e-07 20.2983 8.32951e-07 20.2692C8.32951e-07 19.6107 0.533821 19.0769 1.19231 19.0769Z"
                fill="black"
              />
              <path
                d="M15.5 23.0513C15.8348 23.0513 16.154 22.9106 16.3799 22.6635L22.7389 15.7084C23.1832 15.2224 23.1495 14.4683 22.6636 14.0239C22.1776 13.5796 21.4234 13.6133 20.9791 14.0993L16.6923 18.7879V1.19231C16.6923 0.53382 16.1585 0 15.5 0C14.8415 0 14.3077 0.53382 14.3077 1.19231V18.7879L10.021 14.0993C9.57668 13.6133 8.8225 13.5796 8.33652 14.0239C7.85053 14.4683 7.81675 15.2224 8.26108 15.7084L14.6201 22.6635C14.846 22.9106 15.1652 23.0513 15.5 23.0513Z"
                fill="black"
              />
            </svg>
          }
          text="Mon CV"
        />
        <Routes>
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/profil" element={<ProfilPage />} />
          <Route path="/" element={<ProfilPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </div>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
