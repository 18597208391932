import React from 'react';

interface SkillProps {
  text: string;
  iconUrl: string;
}

function Skill({ text, iconUrl }: SkillProps) {
  return (
    <div className="flex items-center max-w-max space-x-1 sm:space-x-1 md:space-x-2 lg:space-x-1 xl:space-x-1 2xl:space-x-2">
      <img src={iconUrl} alt={`${text} icon`} className="w-2 sm:w-5 md:w-7 lg:w-4 xl:w-5 2xl:w-7" />
      <div className="text-xs sm:text-sm md:text-base lg:text-sm xl:text-base 2xl:text-base">
        {text}
      </div>
    </div>
  );
}

export default Skill;
