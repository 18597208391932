import React from 'react';

export interface TagProps {
  text: string;
  hexaColor: string;
}

function Tag({ text, hexaColor }: TagProps) {
  return (
    <div
      style={{ backgroundColor: hexaColor }}
      className={`max-h-max max-w-max rounded-full text-black font-normal px-2 text-xs sm:font-medium sm:px-4 sm:text-sm md:font-medium md:px-6 md:text-base lg:font-medium lg:px-4 lg:text-xs xl:font-medium xl:px-6 xl:text-sm 2xl:font-medium 2xl:px-6 2xl:text-base`}>
      {text}
    </div>
  );
}

export default Tag;
