import React from 'react';

interface ButtonProps {
  icon: JSX.Element;
  text: string;
}

function Button({ icon, text }: ButtonProps) {
  const downloadCv = () => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/cv.pdf`;
    link.setAttribute('download', 'cv.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button
      className="w-max py-2 px-4 border border-black drop-shadow-myShadowBox rounded-[10px] fixed z-50 bg-tertiary text-black hover:animate-pulse bottom-2 right-2 sm:bottom-5 sm:right-5 md:bottom-5 md:right-5 lg:bottom-5 lg:right-5 xl:bottom-5 xl:right-5 2xl:bottom-10 2xl:right-10"
      onClick={downloadCv}>
      <div className="flex space-x-4 sm:divide-x">
        <div className="flex justify-center items-center">{icon}</div>
        <div className="font-semibold pl-4 text-2xl hidden sm:block">{text}</div>
      </div>
    </button>
  );
}

export default Button;
